import React from 'react'
import Layout from '../components/layout'
// import { Link } from 'gatsby'
import styled from 'styled-components'

const PageHeader = styled.h1 `
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
  color: #000;
  ${'' /* font-weight: 400; */}
`

function ContactPage(props) {
  return (
    <Layout>
      <PageHeader>Contact me</PageHeader>
      <p>Email: jp@jpren.co</p>
    </Layout>
  )
}

export default ContactPage

